<template>
  <div class="users">
    <breadcrumbs :breadcrumbsData="breadcrumbsData">
      <!-- <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-wode2"></use>
      </svg> -->
    </breadcrumbs>
    <div class="filters">
      <div class="search">
        <div class="option">
          <span>Username</span> 
          <a-auto-complete
            v-model:value="filters.sellerName"
            :options="options"
            style="width: 200px"
            size="large"
            @search="onSearch"
            @select="onSelect"
            allow-clear
            @clear="onReset"
            placeholder="Select Username"
            :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
          >
          </a-auto-complete>

        </div>
        <div class="option">
          <span>Campaign</span> 
          <artmisads-input 
          v-model:value="filters.campaignId" 
          @press-enter="getPartData({})"
          placeholder="Enter campaign"
          ></artmisads-input>
        </div>
        <div class="option">
          <span>Brand</span> 
          <artmisads-input 
          v-model:value="filters.brandName" 
          @press-enter="getPartData({})"
          placeholder="Enter brand"
          ></artmisads-input>
        </div>
      </div>
      <div class="button">
          <artmisads-button isSquare="true" type="primary"
          style="margin-right: 8px;"
            @click="getPartData({})"
            @enter="getPartData({})"
            >Search</artmisads-button
          >
          <artmisads-button isSquare="true" @click="onReset">Reset</artmisads-button>
      </div>
      <!-- <div class="export">
        <artmisads-button isSquare="true" @click="exportData">Export</artmisads-button>
      </div> -->
    </div>
    <div class="export">
      <artmisads-button isSquare="true" @click="exportData">Export</artmisads-button>
    </div>
    <div class="table-data">
      <artmisads-table
        v-model:pagination="pagination"
        :columns="columns"
        :data-source="data"
        @change="onTableChange"
        :loading="load"
      >
        <template #bodyCell="{column,record}">
          <template v-if="column.key=='totalProductSales'">
            {{CURRENCY_MAPPER[record.countryCode]?.currency}}{{ parseFloat(record.totalProductSales).toFixed(2)}}
          </template>
          <template v-if="column.key=='totalBonus'">
            {{CURRENCY_MAPPER[record.countryCode]?.currency}}{{ parseFloat(record.totalBonus).toFixed(2) }}
          </template>
        </template>
      </artmisads-table>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRaw, onMounted } from "vue";
import { operatorSellerApi } from "~/server/index";
import breadcrumbs from "../breadcrumbs.vue";
import { SmileOutlined, DownOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import {CURRENCY_MAPPER} from '@/contants/common';
const breadcrumbsData = {
  title: "Sellers' Report",
  des: "Last 30 days",
  // color: "#FFD358",
  // hasTitleIcon: true,
  links: [
    {
      name: "Home",
      path: "/#/operation/users",
    },
    {
      name: "Sellers' Report",
      path: "/#/operation/seller-report",
    },
  ],
};
const columns = [
  {
    title: "Username",
    dataIndex: "sellerName",
    key: "sellerName",
    width:'10%'
  },
  {
    title: "Brand",
    dataIndex: "brandName",
    key: "brandName",
    width:'8%'
  },
  {
    title: "Advertiser Name",
    dataIndex: "advertiserName",
    key: "advertiserName",
    width:'13%'
  },
  {
    title: "Campaings",
    key: "campaignId",
    dataIndex: "campaignId",
    // width:'18%'
  },
  {
    title: "Clicks",
    key: "totalClicks",
    dataIndex: "totalClicks",
    width:'7%',
  },
  {
    title: "DPVs",
    key: "totalDpvs",
    dataIndex: "totalDpvs",
    width:'7%',
  },
  {
    title: "ATCs",
    key: "totalActs",
    dataIndex: "totalActs",
    width:'7%',
  },
  {
    title: "Purchases",
    key: "totalPurchases",
    dataIndex: "totalPurchases",
    width:'10%',
  },
  {
    title: "Sales",
    key: "totalProductSales",
    dataIndex: "totalProductSales",
    width:'10%',
  },
  {
    title: "Bonus",
    key: "totalBonus",
    dataIndex: "totalBonus",
    width:'10%',
  },
];
let filters=reactive({
  sellerName:'',
  campaignId:'',
  sellerId:'',
  brandName:''
});
let data = ref([]);
let load =ref(true);
let options=ref([])
const _options=[];
const pagination = reactive({
  current: 1,
  defaultPageSize: 200,
  size: 200,
  pageSize: 200,
  page: 1,
  total: 50,
  pageSizeOptions:['20','50','100','200'],
  showSizeChanger: true,
});
const onTableChange = ({ current, pageSize }) => {
  pagination.current = current;
  pagination.size = pageSize;
  pagination.pageSize = pageSize;
  getPartData({ page: current, size: pageSize });
};

const init = () => {
  operatorSellerApi.getSellerReport({page:1,size:200}).then((res) => {  
    data.value = res.data;
    pagination.total = res.total;
    res.data.forEach(v=>{
      let isId=_options.find(item=>item.sellerId == v.sellerId);
      if(!isId){
        _options.push({
        value:v.sellerName,
        sellerId:v.sellerId
        });
      }
      
    })
    load.value = false;
  });
};
const getPartData = (params) => { 
  load.value = true;
  let _params = {
    brandName:filters.brandName,
    campaignId:filters.campaignId,
    sellerId:filters.sellerId,
    page: 1,
    size: pagination.size,
  };
  let newparams = { ..._params, ...params };
  newparams = Object.keys(newparams).reduce((acc, key) => {
    if (
      newparams[key] !== null &&
      newparams[key] !== undefined &&
      newparams[key] !== ""
    ) {
      acc[key] = newparams[key];
    }
    return acc;
  }, {});
  operatorSellerApi.getSellerReport(newparams).then((res) => {
    if (res.data) {
      if (params.size || params.page ) {
        document.querySelector(".filters").scrollIntoView({
          behavior: "smooth",
        });
      }
      
      data.value = res.data;
      pagination.total = res.total;
    }
    load.value = false;
  });
};
const onReset=()=>{
  filters.sellerName='';
  filters.campaignId='';
  filters.sellerId='';
  init();
}
const onSelect=e=>{
  let res=_options.find(v=>v.value == e);
  filters.sellerId=res.sellerId;
  getPartData({campaignId:''})
  
}
const onClear=e=>{
  console.log('xishoag');
  
}
const onSearch=e=>{
  options.value=[];
  if(e){
    let res=_options.filter(v=>v.value.toLowerCase().startsWith(e.toLowerCase()))
    options.value=res;
  }
}

const exportData = () => {
  let time=moment().format("YYYY-MM-DD");
  const finalParams = {
    qs:{},
    fileName: `All Sellers' Report_${time}.csv`,
    platform:'operator'
  };
  operatorSellerApi.sellerReportExport(finalParams);
};

onMounted(() => {
  init();
});
</script>

<style lang="less" scoped>
.users {
  padding: 16px;
  padding-top: unset;
  width: 100%;
}
.filters {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  .search {
    display: flex;
    .option {
      >span{
        margin-right: 8px;
      }
      display: flex;
      align-items: center;
      margin-right: 24px;
    }
  }
}
.export{
  // margin-left: 16px ;
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
}

.table-data:deep {
  word-break: break-all;
  border-radius: 8px;
  border: 1px solid #fff;
  .ant-table {
    border-radius: 8px 8px 0 0;
  }
  .ant-table-thead{
    border-radius: 8px 8px 0 0;
  }
}
.show-url {
  word-break: break-all;
}
</style>
